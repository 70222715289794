import React, { useState } from 'react'
import Navbar from '../Navbar'
import { Link } from 'react-scroll';
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements";
import Sidebar from '../Sidebar';

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
   <HeroContainer>
    <Navbar toggle={toggle}/>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
      <HeroContent>
        <HeroItems>
          <HeroH1>Carmen's La Hacienda</HeroH1>
          <HeroP>Fairfax</HeroP>
          <a href='tel:+14154195181'>
            <HeroBtn>Call</HeroBtn>
          </a>
          <a href='https://maps.app.goo.gl/B7Cd3jKvd4XgfwkV9'>
            <HeroBtn>Directions</HeroBtn>
          </a>
            <HeroBtn>
              <Link to="menu" smooth={true} duration={500} offset={-70}>
                    Full Menu
              </Link>
            </HeroBtn>
        </HeroItems>
      </HeroContent>
   </HeroContainer>
  )
}

export default Hero