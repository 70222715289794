import React, { useState } from 'react';
import './CarouselMenu.css';
import menuItems from './MenuItems.js';


const CarouselMenu = () => {
    const [ activeCategory, setActiveCategory ] = useState('Starters');

    
    return (
        <div className='menu'>
            <div className='fullMenu'><h1> Menu</h1></div>
            <div className='carousel'>
                
                {Object.keys(menuItems).map((category) => (
                    <button
                      key={category}
                      className={`carousel-item ${activeCategory === category ? 'active' : ''}`}
                      onClick={() => setActiveCategory(category)}
                    >
                        {category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </button>
                    
                ))}
            </div>
            <div className='cards-container'>
                {menuItems[activeCategory].map((item) => (
                    <div key={item.name} className='card'>
                        <div className='name-price'>
                        <h3>{item.name}</h3>
                        <p>{item.price}</p>
                        </div>
                        <p>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
        

        
    )

    
}

export default CarouselMenu;