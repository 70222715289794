import React from "react";
import './AboutUs.css'

const AboutUs = () => {
    return (
        <div className="aboutUsContainer">
            <h1>About Us</h1>
            {/* <h2></h2> */}
            <p>
            Carmen's La Hacienda is thrilled to announce our expansion to Fairfax, CA. We are proud to offer an extensive selection of traditional Mexican cuisine, margaritas, and craft beers. Our restaurant has become a local favorite, not only for its classic dishes but also for our commitment to using only the freshest, high-quality ingredients.


            </p>
            <br></br>
            <p>
                Whether you're looking to enjoy a meal out or prefer the convenience of takeout or delivery, Carmen La Hacienda provides exceptional dining options throughout the day, from breakfast through dinner. Dine with us at our welcoming location at 715 Center Blvd, Fairfax, CA 94930, which features ample parking, or order a meal to savor at home.
            </p>
            <br></br>
            <p>
                For takeout orders, please contact us directly. Experience the authentic flavors of Mexico, lovingly prepared and served with hospitality that only a family-owned restaurant can offer.
            </p>
        </div>
    )
}

export default AboutUs;