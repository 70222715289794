import React from "react";
import './BusinessHours.css'

const BusinessHours = () => {
    return (
        <div className="business-hours-container">
            <div className="hours-card">
                <h2>Hours</h2>
                <ul>
                    <li>Monday: 10:00 AM - 9 PM</li>
                    <li>Tuesday: 10:00 AM - 9 PM</li>
                    <li>Wednesday: 10:00 AM - 9 PM</li>
                    <li>Thursday: 10:00 AM - 9 PM</li>
                    <li>Friday: 10:00 AM - 9 PM</li>
                    <li>Saturday: 10:00 AM - 9 PM</li>
                    <li>Sunday: 10:00 AM - 9 PM</li>
                </ul>
            </div>
        </div>
    )
}

export default BusinessHours;